import logo from "./logo.svg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

const LandingPage = React.lazy(() => import("./pages/landingPage"));
const GetInTouch = React.lazy(() => import("./pages/getInTouch"));
const ComingSoonPage = React.lazy(() => import("./pages/comingSoon"));
function App() {
  return (
    <BrowserRouter>
      <React.Suspense>
        <Routes>
          <Route>
            <Route exact path="/" element={<ComingSoonPage />} />
            <Route exact path="/home" element={<LandingPage />} />
            <Route exact path="/get-in-touch" element={<GetInTouch />} />
          </Route>
          <Route
            path="*" 
            element={
              <main>
                <h2>Page Not Found</h2>
                <p>Sorry something went wrong...</p>
              </main>
            }
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
